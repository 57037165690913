const CONTACT_FORMS = document.querySelectorAll('form[data-contact]');

export function INIT_FORMS() {
  CONTACT_FORMS.forEach(FORM => {
    let FIELDS = {};

    const SUCCESS_BOX = FORM.querySelector('[data-success-box]'),
      ERROR_BOX = FORM.querySelector('[data-error-box]'),
      FORM_FIELD_NODES = FORM.querySelectorAll('[name][id]');

    FORM_FIELD_NODES.forEach(field => {
      FIELDS[field.name] = field
    });

    const HANDLE_ERROR = response => {
      let errors = [];

      for (const key in FIELDS) {

        if (Object.prototype.hasOwnProperty.call(response, key)) {

          FIELDS[key].setAttribute('data-validation-status', 'invalid');
          Array.prototype.push.apply(errors, response[key]);

        } else {
          FIELDS[key].setAttribute('data-validation-status', 'valid');
        }
      }

      ERROR_BOX.setAttribute('data-error', '');
      ERROR_BOX.querySelector('.form-error-text').innerHTML = errors.join('<br>');
    }

    const VALIDATION_HANDLER = e => {
      if (e.target.status === 200) {
        for (const key in FIELDS) {
          FIELDS[key].setAttribute('data-validation-status', 'valid');
        }
        ERROR_BOX.removeAttribute('data-error');
      } else {
        HANDLE_ERROR(JSON.parse(e.target.response));
      }
    };

    const REQUEST_HANDLER = e => {
      if (e.target.status === 200) {
        const FORM_TYPE = FORM.getAttribute('data-form-type'),
          FORM_RECIPIENT = FORM.getAttribute('data-form-recipient'),
          FORM_PAGE = FORM.getAttribute('data-form-page');

        const successfulSubmit = new CustomEvent('successful-form-submit', {
          detail: {
            type: FORM_TYPE,
            page: FORM_PAGE,
            recipient: FORM_RECIPIENT
          }
        });

        document.dispatchEvent(successfulSubmit);

        for (const key in FIELDS) {
          FIELDS[key].setAttribute('data-validation-status', 'valid');
        }

        ERROR_BOX.removeAttribute('data-error');
        SUCCESS_BOX.setAttribute('data-success', '');
      } else {
        FORM_FIELD_NODES.forEach(FIELD => {
          FIELD.addEventListener('change', () => {
            const REQUEST = new XMLHttpRequest();
            REQUEST.open('POST', FORM.action);
            REQUEST.onload = VALIDATION_HANDLER;

            let FORM_DATA = new FormData(FORM);
            FORM_DATA.append('mode', 'validation')

            REQUEST.send(FORM_DATA);
          })
        })
        HANDLE_ERROR(JSON.parse(e.target.response));
      }
    };

    function FORM_SUBMIT(submitEvent) {
      const REQUEST = new XMLHttpRequest();
      REQUEST.open('POST', submitEvent.target.action);
      REQUEST.onload = REQUEST_HANDLER;
      REQUEST.send(new FormData(submitEvent.target));
    }

    FORM.addEventListener('submit', e => {
      e.preventDefault();
      FORM_SUBMIT(e);
    });
  });
}
