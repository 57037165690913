export function INIT_FORM_MODAL() {
  document.querySelectorAll('footer .modal').forEach(FOOTER_MODAL_ELEMENT => {
    FOOTER_MODAL_ELEMENT.addEventListener('show.bs.modal', event => {
      const TRIGGER_BUTTON = event.relatedTarget,
        FORM_SUBJECT_TEXT = TRIGGER_BUTTON.getAttribute('data-bs-form-subject'),
        FORM_RECIPIENT = TRIGGER_BUTTON.getAttribute('data-bs-form-recipient'),
        FORM_SUBJECT_HOLDER = FOOTER_MODAL_ELEMENT.querySelector('[data-subject-holder]') ?? false,
        FORM_SUBJECT_FIELD = FOOTER_MODAL_ELEMENT.querySelector('input[name="subject"]') ?? false,
        FORM = FOOTER_MODAL_ELEMENT.querySelector('form');

      FORM.setAttribute('data-form-recipient', FORM_RECIPIENT);
      if (FORM_SUBJECT_TEXT && FORM_SUBJECT_HOLDER) {
        FORM_SUBJECT_HOLDER.classList.remove('d-none');
        FORM_SUBJECT_FIELD.value = FORM_SUBJECT_TEXT;
      }
    })
  })
}
