export function INIT_NON_PARTNER_FATHOM_LINKS() {
  const FATHOM = typeof fathom == 'undefined' ? false : fathom;
  if (!FATHOM) return;

  const NON_PARTNER_TRACK_LINKS = document.querySelectorAll('body:not(.is--partner-detail) a[href^="tel"], body:not(.is--partner-detail) button[href^="tel"], body:not(.is--partner-detail) a[href^="mailto"], body:not(.is--partner-detail) button[href^="mailto"]'),
    C2A_BUTTON = document.querySelector('.footer-cta-content > a.btn'),
    PAGE_TITLE = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  if (C2A_BUTTON) {
    C2A_BUTTON.addEventListener('click', () => FATHOM.trackEvent(`C2A – Click: ${C2A_BUTTON.title}`));
  }

  NON_PARTNER_TRACK_LINKS.forEach(LINK => {
    let LEAD_STRING = false;

    if (LINK.href.includes('tel')) {
      LEAD_STRING = `Lead – Call: ${LINK.href.replace('tel:', '')} (/${PAGE_TITLE})`
    } else if (LINK.href.includes('mailto')) {
      LEAD_STRING = `Lead – Mail: ${LINK.href.replace('mailto:', '')} (/${PAGE_TITLE})`
    }

    if (LEAD_STRING) LINK.addEventListener('click', () => FATHOM.trackEvent(LEAD_STRING));
  })
}

export function INIT_PARTNER_FATHOM_LINKS() {
  const FATHOM = typeof fathom == 'undefined' ? false : fathom;
  if (!FATHOM) return;

  const PARTNER_PHONE_LINKS = document.querySelectorAll('body.is--partner-detail .partner-card a[href^="tel"]'),
    PARTNER_MAIL_LINKS = document.querySelectorAll('body.is--partner-detail .partner-card a[href^="mailto"]'),
    PARTNER_WEBSITE_LINKS = document.querySelectorAll('body.is--partner-detail .partner-card a[href^="https"]:not(.btn)'),
    PARTNER_BUTTONS = document.querySelectorAll('body.is--partner-detail .partner-card .btn');

  PARTNER_PHONE_LINKS.forEach(PARTNER_PHONE_LINK => FATHOM.trackEvent('Partner - Phone'));
  PARTNER_MAIL_LINKS.forEach(PARTNER_MAIL_LINK => FATHOM.trackEvent('Partner - Mail'));
  PARTNER_WEBSITE_LINKS.forEach(PARTNER_WEBSITE_LINK => FATHOM.trackEvent('Partner - Website'));
  PARTNER_BUTTONS.forEach(PARTNER_BUTTON => FATHOM.trackEvent('Partner - Button'));
}
