export function INIT_FATHOM_FORMS() {
  const FATHOM = typeof fathom == 'undefined' ? false : fathom;
  if (!FATHOM) return;

  document.addEventListener('successful-form-submit', e => {
    let EVENT_STRING = `Lead – Form – Submit: ${e.detail.recipient}`;

    if (e.detail.type === 'product') EVENT_STRING = `Product – Form – Submit: ${e.detail.page} (${e.detail.recipient})`;
    else if (e.detail.type === 'training') EVENT_STRING = `Training – Form – Submit: ${e.detail.recipient} (${e.detail.page})`;

    FATHOM.trackEvent(EVENT_STRING);
  })
}
